import React, { useContext, useEffect, useState } from 'react';
import { getCookieValue, getItemFromSessionStore, setItemToSessionStore } from './helpers/utils';
import jwtDecode from 'jwt-decode';
import { isNil } from 'lodash';

export const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(()=>{
        const userData = getItemFromSessionStore("userData");

        if (userData && userData.Tokens){
            setCurrentUser(userData.Tokens[0]);
            return;
        }

        const cookieData = JSON.parse(getCookieValue("cc_userdata"));

        if (cookieData && cookieData.Tokens){
            setItemToSessionStore("userData", cookieData);
            setCurrentUser(cookieData.Tokens[0]);
        }

    },[]);

    const getRoles = () => {
        let result = [];

        if (currentUser){
            result = jwtDecode(currentUser.Token).role;
        }

        return result;
    }

    const getSID = () => {
        let result = null;

        if (currentUser){
            result = jwtDecode(currentUser.Token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];
        }

        return result;
    }

    const getUsername = () => {
        let result = null;

        if (currentUser){
            result = jwtDecode(currentUser.Token)["unique_name"];
        }

        return result;
    }

    const setToken = (tok) => {
        const temp = { Token: tok };

        setItemToSessionStore("userData", {Tokens: [temp]});
        setCurrentUser(temp);
    } 

    const isLoggedIn = () => {
        return !isNil(currentUser);
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: isLoggedIn(),
                token: currentUser ? currentUser.Token : null,
                setToken,
                roles: getRoles(),
                sid: getSID(),
                currentUser,
                username: getUsername(),
                isCashier: isNil(getRoles()) ? false : getRoles().includes("Cashier") || getRoles().includes("Admin") || getRoles().includes("GlobalAdmin"),
                isAdmin: isNil(getRoles()) ? false : getRoles().includes("Admin") || getRoles().includes("GlobalAdmin"),
                isGlobalAdmin: isNil(getRoles()) ? false : getRoles().includes("GlobalAdmin")
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};